<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small">
            <el-form-item>
              <el-input v-model="name" placeholder="请输入职务名称搜索" clearable>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>配置会员顾问职务</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table
        size="small"
        :data="tableData.filter(data=>!name || data.JobName.toLowerCase().includes(name.toLowerCase()))"
      >
        <el-table-column prop="JobName" label="职务名称"></el-table-column>
        <el-table-column prop="JobDescription" label="职责说明"></el-table-column>
      </el-table>
    </div>

    <!--新增弹窗-->
    <el-dialog title="配置会员顾问职务" :visible.sync="dialogVisible" width="50%">
      <div>
        <el-form :model="ruleForm" ref="ruleForm" size="small" class="marbm_5">
          <el-input size="small" v-model="ruleForm.searchTitle" clearable placeholder="请输入职务名称搜索">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form>
        <el-table
          ref="jobTable"
          :row-key="getRowKeys"
          :data="jobTypeList.filter(data=>!ruleForm.searchTitle || data.JobName.toLowerCase().includes(ruleForm.searchTitle.toLowerCase()))"
          size="small"
          max-height="450px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :reserve-selection="true" width="50"></el-table-column>
          <el-table-column prop="JobName" label="职务名称"></el-table-column>
          <el-table-column prop="JobDescription" label="职责说明"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click>取 消</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitJob"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customerConsultantJobType";
export default {
  name: "CustomerConsultantJobType",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      name: "",
      tableData: [],
      jobTypeList: [],
      selectJobList: [],
      ruleForm: {
        searchTitle: ""
      }
    };
  },
  methods: {
    getRowKeys(row) {
      return row.ID;
    },

    // 数据显示
    search: function() {
      let that = this;
      that.loading = true;
      API.customerConsultantJobType()
        .then(res => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 新增弹窗
    showAddDialog: function() {
      var that = this;
      that.dialogVisible = true;
      that.$nextTick(function() {
        that.$refs.jobTable.clearSelection();
        that.tableData.forEach(p => {
          that.jobTypeList.forEach(s => {
            if (p.JobTypeID == s.ID) {
              that.$refs.jobTable.toggleRowSelection(s, true);
            }
          });
        });
      });
    },
    //新增保存
    submitJob() {
      let that = this;
      if (that.selectJobList.length < 1) {
        that.$message.error({
          message: "请选择职务",
          duration: 2000
        });
        return;
      } else {
        var ids = [];
        that.modalLoading = true;
        that.selectJobList.forEach(p => {
          ids.push(p.ID);
        });
        var params = {
          JobTypeID: ids
        };
        API.customerConsultantJobTypeUpdate(params)
          .then(res => {
            if (res.StateCode == 200) {
              that.$message.success({
                message: res.Message,
                duration: 2000
              });
              that.search();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000
              });
            }
          })
          .finally(function() {
            that.dialogVisible = false;
            that.modalLoading = false;
          });
      }
    },
    //获取职务类表不分页
    getjobtype() {
      let that = this;
      that.loading = true;
      var params = {
        JobTypeName: null
      };
      API.jobtypeAll(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.jobTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //获取选中的数据
    handleSelectionChange(val) {
      var that = this;
      that.selectJobList = val;
    },
  },
  mounted() {
    var that = this;
    that.search();
    that.getjobtype();
  }
};
</script>

<style lang="scss">
</style>
