/**
 * Created by jerry on 2020/04/13.
 * 营销顾问配置api
 */
import * as API from '@/api/index'

export default {
    // 获取营销顾问配置列表
    customerConsultantJobType: params => {
        return API.POST('api/customerConsultantJobType/all', params)
    },
    // 保存职务配置
    customerConsultantJobTypeUpdate: params => {
        return API.POST('api/customerConsultantJobType/update', params)
    },
    //删除职务配置
    customerConsultantJobTypeDelete: params => {
        return API.POST('api/customerConsultantJobType/delete', params)
    },
    //获取职务列表不分页
    jobtypeAll: params => {
        return API.POST('api/jobtype/all', params)
    },
}